import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { ShowcaseCarousel } from "../../../components/showcase-carousel";
import { DOG_SHOWCASE_IMAGES } from "../../../../consts/config";

const ShowcaseSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  if (isMobile) {
    return (
      <Box padding={"60px 16px"}>
        <ShowcaseCarousel data={DOG_SHOWCASE_IMAGES} />
      </Box>
    );
  }

  return (
    <Grid container padding={"120px 108px"}>
      <Grid item xs={12} md={6}>
        <Box>
          <Box maxWidth={"644px"} height={"440px"}>
            <img
              src="/dog1.png"
              alt="dog1"
              style={{
                borderRadius: "40px",
                maxWidth: "100%",
              }}
            />
          </Box>
          <Stack direction={"row"} mt={"34px"} spacing={"8px"}>
            <img
              src="/dog5.png"
              alt="dog5"
              style={{
                borderRadius: "40px",
                maxWidth: "100%",
              }}
            />
            <img
              src="/dog3.png"
              alt="dog3"
              style={{
                borderRadius: "40px",
                maxWidth: "100%",
              }}
            />
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box>
          <Stack direction={"row"} mb={"34px"} spacing={"8px"}>
            <img
              src="/dog2.png"
              alt="dog2"
              style={{
                borderRadius: "40px",
              }}
            />
            <img
              src="/dog6.png"
              alt="dog6"
              style={{
                borderRadius: "40px",
              }}
            />
          </Stack>
          <Box maxWidth={"644px"} height={"440px"}>
            <img
              src="/dog4.png"
              alt="dog4"
              style={{
                borderRadius: "40px",
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export { ShowcaseSection };
