import { Box } from "@mui/system";
import { useEffect, useRef } from "react";
import { Autoplay } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import { IconsTile } from "../../../types";
import { IconTile } from "../icon-tile";

interface Props {
  data: IconsTile[];
}

const IconsCarousel = ({ data }: Props) => {
  const renderCarouselBody = () => {
    return data.map((slide) => (
      <SwiperSlide key={"carousel-item" + slide.alt}>
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <IconTile data={slide} />
        </Box>
      </SwiperSlide>
    ));
  };

  const stopCarousel = false;
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    if (stopCarousel && swiperRef?.current) {
      swiperRef.current.autoplay.stop();
    }

    if (!stopCarousel && swiperRef?.current) {
      swiperRef.current.autoplay.start({
        delay: 100,
        disableOnInteraction: false,
      });
    }
  }, [swiperRef?.current, stopCarousel]);

  return (
    <Box width={"100%"}>
      <Swiper
        onInit={(swiper: any) => {
          swiperRef.current = swiper;
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay]}
        slidesPerView={1}
        style={{
          maxWidth: "100%",
        }}
      >
        {renderCarouselBody()}
      </Swiper>
    </Box>
  );
};

export { IconsCarousel };
