import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import { themeConfig } from "../../../../theme";

const InfoSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  return (
    <Stack
      padding={"120px 16px"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box maxWidth={isMobile ? "490px" : "800px"} textAlign={"center"}>
        <Typography sx={themeConfig.typography.headerSecondary}>
          {t("informations.title")}
        </Typography>
        <Typography sx={themeConfig.typography.textPrimary} mt={"24px"}>
          {t("informations.description")}
        </Typography>
      </Box>
    </Stack>
  );
};

export { InfoSection };
