import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { themeConfig } from "../../../../theme";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  return (
    <Box component={"div"} id="about">
      <Grid container padding={isMobile ? "50px 16px" : "100px 108px"}>
        <Grid item xs={12} md={6}>
          <Box
            display={"flex"}
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            <img
              src="/aboutImage.png"
              alt="aboutImage"
              style={{
                borderRadius: "40px",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction={"row"}
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={isMobile ? "center" : "flex-end"}
            mt={isMobile ? "24px" : 0}
          >
            <Box
              maxWidth={"482px"}
              width={"100%"}
              textAlign={isMobile ? "center" : "inherit"}
            >
              <Typography sx={themeConfig.typography.headerSecondary}>
                {t("aboutUs.title")}
              </Typography>
              <Typography sx={themeConfig.typography.textPrimary} mt={"24px"}>
                {t("aboutUs.description")}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export { AboutSection };
