import { Box } from "@mui/system";
import { useEffect, useRef } from "react";
import { Autoplay } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import { ExternalImage } from "../../../types";

interface Props {
  data: ExternalImage[];
}

const ShowcaseCarousel = ({ data }: Props) => {
  const renderCarouselBody = () => {
    return data.map((slide: any) => (
      <SwiperSlide key={slide.alt}>
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img
            src={slide.source}
            alt={slide.alt}
            style={{
              borderRadius: "40px",
              maxWidth: "600px",
              width: "100%",
            }}
          />
        </Box>
      </SwiperSlide>
    ));
  };

  const stopCarousel = false;
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    if (stopCarousel && swiperRef?.current) {
      swiperRef.current.autoplay.stop();
    }

    if (!stopCarousel && swiperRef?.current) {
      swiperRef.current.autoplay.start({
        delay: 100,
        disableOnInteraction: false,
      });
    }
  }, [swiperRef?.current, stopCarousel]);

  return (
    <Box width={"100%"}>
      <Swiper
        onInit={(swiper: any) => {
          swiperRef.current = swiper;
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay]}
        slidesPerView={1}
        style={{
          maxWidth: "100%",
        }}
      >
        {renderCarouselBody()}
      </Swiper>
    </Box>
  );
};

export { ShowcaseCarousel };
