import { PageWrapper } from "../ui/components/page-wrapper";
import { AmstaffHeroSection } from "../ui/sections/amstaff/hero";

const AmstaffBreedPage = () => {
  return (
    <PageWrapper>
      <AmstaffHeroSection />
    </PageWrapper>
  );
};

export default AmstaffBreedPage;
