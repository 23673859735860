import { ReactNode } from "react";
import { Navbar } from "../navbar";
import { Footer } from "../footer";

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export { Layout };
