import { Box, IconButton, Stack, Typography } from "@mui/material";
import { IconsTile } from "../../../types";
import { themeConfig } from "../../../theme";
import { NavigateNext } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Props {
  data: IconsTile;
}

const IconTile = (props: Props) => {
  const breed = props.data;
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Stack
      direction={"row"}
      spacing={"42px"}
      alignItems={"center"}
      onClick={() => navigate(breed.route)}
      sx={{
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      <img
        src={breed.imageSrc}
        alt={breed.alt}
        width={breed.width}
        height={breed.height}
      />
      <Stack direction={"column"}>
        <Typography sx={themeConfig.typography.showCaseIconsHeader}>
          {breed.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography>{t("homepage.tiles.learnMore")}</Typography>
          <IconButton>
            <NavigateNext />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};

export { IconTile };
