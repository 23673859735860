// @ts-ignore
import FieldsSemiBold from "./assets/fonts/fields-semi-bold.woff2";
import { createTheme } from "@mui/material";

export const themeConfig = {
  fonts: {
    fieldsFont: "Fields Semi Bold",
  },
  typography: {
    navbarItem: {
      fontFamily: "Hind",
      fontSize: "16px",
      fontStyle: "normal",
      lineHeight: "32px",
      padding: "0 8px",
      ":hover": {
        cursor: "pointer",
        borderBottom: "1px solid #101820",
        userSelect: "none",
      },
    },
    navbarItemMobile: {
      fontFamily: "Hind",
      fontSize: "32px",
      fontStyle: "normal",
      lineHeight: "48px",
      padding: "0 8px",
      ":hover": {
        cursor: "pointer",
        borderBottom: "1px solid #101820",
        userSelect: "none",
      },
    },

    navbarHeading: {
      fontFamily: "Fields Semi Bold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "54px",
    },
    headerPrimary: {
      fontFamily: "Fields Semi Bold",
      fontSize: "56px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "54px",
    },
    headerSecondary: {
      fontFamily: "Fields Semi Bold",
      fontSize: "42px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "54px",
    },
    textPrimary: {
      fontFamily: "Hind",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "32px",
    },
    footerHeader: {
      fontFamily: "Fields Semi Bold",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "54px",
    },
    footerText: {
      fontFamily: "Hind",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "54px",
    },
    footerSmallText: {
      fontFamily: "Hind",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "48px",
    },
    showCaseIconsHeader: {
      fontFamily: "Fields Semi Bold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "48px",
    },
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#101820",
    },
    secondary: {
      main: "#4F34B1",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Fields Semi Bold';
          font-style: normal;
          font-weight: 400;
          src: url(${FieldsSemiBold}) format('woff2');
          font-display: swap;
        }
      `,
    },
  },
});
