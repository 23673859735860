import { PageWrapper } from "../ui/components/page-wrapper";
import { BulterierHeroSection } from "../ui/sections/bullterrier/hero";

const BullterrierBreedPage = () => {
  return (
    <PageWrapper>
      <BulterierHeroSection />
    </PageWrapper>
  );
};

export default BullterrierBreedPage;
