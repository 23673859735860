import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Cookies, getCookie, setCookie } from "./cookies";

import en from "./en.json";
import pl from "./pl.json";
import de from "./de.json";
import nl from "./nl.json";

const languageDetector = new LanguageDetector();

const resources = {
  en,
  pl,
  de,
  nl,
};

export enum Language {
  en = "en",
  pl = "pl",
  nl = "nl",
  de = "de",
}

export const getLanguageFromStorage = () => {
  const language = getCookie(Cookies.language);
  // TO DO: get data from detector
  return language ? language : Language.pl;
};

const setLanguage = (language: Language) => {
  setCookie(Cookies.language, language, 30);
};

export const changeLanguage = (language: Language) =>
  i18n.changeLanguage(language);

const myDetector = {
  name: "myDetector",
  lookup() {
    return getLanguageFromStorage();
  },
  cacheUserLanguage(lng: Language) {
    setLanguage(lng);
  },
};

const options = {
  order: ["myDetector"],
  caches: ["myDetector"],
};

languageDetector.addDetector(myDetector);

i18n.use(initReactI18next).use(languageDetector).init({
  resources,
  detection: options,
  fallbackLng: "en",
});

export default i18n;
