import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { themeConfig } from "../../../../theme";
import { Button } from "../../../components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../../consts/routes";
import { scrollToElement } from "../../../../utils/scroll-to-element";

const HeroSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const scrollToSpecificSection = (name: string) => {
    if (location.pathname !== routes.home) {
      navigate(routes.home);

      setTimeout(() => scrollToElement(name, true), 537);
      return;
    }

    scrollToElement(name);
  };

  return (
    <Grid container padding={isMobile ? "44px 16px" : "88px 108px"}>
      <Grid
        item
        xs={12}
        md={6}
        order={{
          xs: 2,
          md: 1,
        }}
      >
        <Stack
          height={"100%"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={isMobile ? "center" : "flex-start"}
          mt={isMobile ? "24px" : 0}
        >
          <Box maxWidth={"494px"} textAlign={isMobile ? "center" : "inherit"}>
            <Typography sx={themeConfig.typography.headerPrimary}>
              {t("homepage.hero.title")}
            </Typography>
            <Typography
              mt={"18px"}
              mb={"41px"}
              sx={themeConfig.typography.textPrimary}
            >
              {t("homepage.hero.description")}
            </Typography>
            <Button
              _variant={"contained"}
              size="large"
              onClick={() => scrollToSpecificSection("localization")}
            >
              {t("homepage.hero.button")}
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        order={{
          xs: 1,
          md: 2,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={isMobile ? "center" : "flex-end"}
          width={"100%"}
        >
          <Box>
            <Box position={"relative"} overflow={"hidden"}>
              <img
                src="/heroOverlay.png"
                alt="hero-overlay"
                style={{
                  maxWidth: "100%",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "20px",
                  left: "-120px",
                }}
              >
                <img src="/heroDog.png" alt="hero-dog" />
              </Box>
            </Box>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export { HeroSection };
