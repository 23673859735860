import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { IconsCarousel } from "../../../components/icons-carousel";
import { BREEDS_CONFIG } from "../../../../consts/config";
import { IconTile } from "../../../components/icon-tile";

const BreedsIconsSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (isMobile) {
    return (
      <Box padding={"80px 16px"} component={"div"} id="breeds">
        <IconsCarousel data={BREEDS_CONFIG} />
      </Box>
    );
  }

  return (
    <Box component={"div"} id="breeds">
      <Stack
        direction={"row"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={"80px 212px"}
      >
        {BREEDS_CONFIG.map((breed) => (
          <IconTile data={breed} key={"icon-tile" + breed.alt} />
        ))}
      </Stack>
    </Box>
  );
};

export { BreedsIconsSection };
