import React, { useState } from "react";
import { MenuItem, Menu, IconButton } from "@mui/material";
import { GB, PL, NL, DE } from "country-flag-icons/react/3x2";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import i18n, { Language, changeLanguage } from "../../../translations/i18n";

const LanguageSelector = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderSelectedLanguageFlag = () => {
    const iconStyle = {
      width: "24px",
      height: "24px",
    };

    switch (i18n.language) {
      case Language.pl:
        return <PL title="Poland" style={iconStyle} />;

      case Language.nl:
        return <NL title="Netherlands" style={iconStyle} />;
      case Language.de:
        return <DE title="Deutschland" style={iconStyle} />;

      default:
        return <GB title="England" style={iconStyle} />;
    }
  };

  const isMenuOpen = anchorEl !== null;

  const handleLanguageSelect = (language: Language) => {
    changeLanguage(language);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        {renderSelectedLanguageFlag()}
        {isMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      </IconButton>

      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLanguageSelect(Language.pl)}>
          PL
        </MenuItem>
        <MenuItem onClick={() => handleLanguageSelect(Language.en)}>
          EN
        </MenuItem>
        <MenuItem onClick={() => handleLanguageSelect(Language.nl)}>
          NL
        </MenuItem>
        <MenuItem onClick={() => handleLanguageSelect(Language.de)}>
          DE
        </MenuItem>
      </Menu>
    </>
  );
};

export { LanguageSelector };
