import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Menu as MuiMenu,
} from "@mui/material";
import { themeConfig } from "../../../theme";
import { Close, Menu } from "@mui/icons-material";
import { Button } from "../button";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import i18n from "../../../translations/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../consts/routes";
import { scrollToElement } from "../../../utils/scroll-to-element";
import { useEffect, useState } from "react";
import { LanguageSelector } from "../language-selector";

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [breedsMenuAnchorEl, setBreedsMenuAnchorEl] = useState(null);

  const handleBreedsMenuOpen = (event: any) => {
    setBreedsMenuAnchorEl(event.currentTarget);
  };

  const handleBreedsMenuClose = () => {
    setBreedsMenuAnchorEl(null);
  };

  const scrollToSpecificSection = (name: string) => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }

    if (location.pathname !== routes.home) {
      navigateToHomePage();

      setTimeout(() => scrollToElement(name, true), 537);
      return;
    }

    scrollToElement(name);
  };

  const navigateToHomePage = () => {
    if (location.pathname !== routes.home) {
      navigate(routes.home);
    }
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "visible";
  }, [isMenuOpen]);

  return (
    <>
      <Stack
        padding={isMobile ? "20px 16px" : "32px 108px"}
        width={"100%"}
        direction={"row"}
        justifyContent={"center"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          maxWidth={isMobile ? "600px" : "100%"}
        >
          <Typography
            sx={{
              ...themeConfig.typography.navbarHeading,
              ":hover": { cursor: "pointer" },
            }}
            onClick={() => navigateToHomePage()}
          >
            LawendowyEden
          </Typography>

          {isMobile ? (
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
              <LanguageSelector />
              <IconButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {isMenuOpen ? <Close /> : <Menu />}
              </IconButton>
            </Stack>
          ) : (
            <Stack direction={"row"} alignItems={"center"} spacing={"64px"}>
              <Stack direction={"row"} alignItems={"center"} spacing={"80px"}>
                <Typography
                  sx={themeConfig.typography.navbarItem}
                  onClick={() => navigateToHomePage()}
                >
                  {t("navbar.homepage")}
                </Typography>
                <Typography
                  sx={themeConfig.typography.navbarItem}
                  onClick={() => scrollToSpecificSection("about")}
                >
                  {t("navbar.aboutUs")}
                </Typography>
                <Typography
                  sx={themeConfig.typography.navbarItem}
                  aria-controls="breeds-menu"
                  aria-haspopup="true"
                  onMouseEnter={handleBreedsMenuOpen}
                >
                  {t("navbar.breeds")}
                </Typography>

                <MuiMenu
                  id="breeds-menu"
                  anchorEl={breedsMenuAnchorEl}
                  keepMounted
                  open={Boolean(breedsMenuAnchorEl)}
                  onClose={handleBreedsMenuClose}
                  MenuListProps={{
                    onMouseLeave: handleBreedsMenuClose,
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(routes.amstaff);
                      handleBreedsMenuClose();
                    }}
                  >
                    Amstaff
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate(routes.bulterier);
                      handleBreedsMenuClose();
                    }}
                  >
                    Bulterier
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate(routes.americanBully);
                      handleBreedsMenuClose();
                    }}
                  >
                    American Bully
                  </MenuItem>
                </MuiMenu>
              </Stack>
              <LanguageSelector />
              <Button
                _variant="outlined"
                size="medium"
                onClick={() => scrollToSpecificSection("localization")}
              >
                {t("navbar.contactUsButton")}
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
      {isMenuOpen && isMobile && (
        <Box
          height={"100%"}
          width={"100%"}
          maxWidth={"100%"}
          minHeight={"100vh"}
          zIndex={"999"}
          position={"fixed"}
          top={"88px"}
          padding="24px 20px"
          sx={{
            background: "#FFF",
          }}
        >
          <Stack direction={"column"} alignItems={"center"} mt={4}>
            <Stack direction={"column"} alignItems={"center"} spacing={"32px"}>
              <Typography
                sx={themeConfig.typography.navbarItemMobile}
                onClick={() => navigateToHomePage()}
              >
                {t("navbar.homepage")}
              </Typography>
              <Typography
                sx={themeConfig.typography.navbarItemMobile}
                onClick={() => scrollToSpecificSection("about")}
              >
                {t("navbar.aboutUs")}
              </Typography>
              <Typography
                sx={themeConfig.typography.navbarItemMobile}
                onClick={() => scrollToSpecificSection("breeds")}
              >
                {t("navbar.breeds")}
              </Typography>
              <Typography
                sx={{
                  marginTop: "16px",
                  ":hover": { cursor: "pointer" },
                }}
                onClick={() => {
                  navigate(routes.amstaff);
                }}
              >
                Amstaff
              </Typography>
              <Typography
                onClick={() => {
                  navigate(routes.bulterier);
                }}
                sx={{
                  ":hover": { cursor: "pointer" },
                }}
              >
                Bulterier
              </Typography>
              <Typography
                onClick={() => {
                  navigate(routes.americanBully);
                }}
                sx={{
                  ":hover": { cursor: "pointer" },
                }}
              >
                American Bully
              </Typography>
            </Stack>
            <Box
              mt={"64px"}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Button
                _variant="outlined"
                size="medium"
                onClick={() => scrollToSpecificSection("localization")}
              >
                {t("navbar.contactUsButton")}
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
};

export { Navbar };
