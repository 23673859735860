import { ReactNode } from "react";
import { Button as MuiButton } from "@mui/material";

interface Props {
  children: ReactNode;
  onClick?: () => void;
  _variant: "contained" | "outlined";
  size: "medium" | "large";
}

const Button = ({ children, onClick, _variant, size = "medium" }: Props) => {
  return (
    <MuiButton
      onClick={onClick}
      variant={_variant as any}
      sx={{
        fontFamily: "Hind",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "32px",
        borderRadius: "28px",
        textTransform: "none",
        padding: size === "medium" ? "10px 24px" : "14px 32px",
      }}
    >
      {children}
    </MuiButton>
  );
};

export { Button };
