import {
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { themeConfig } from "../../../theme";
import { Facebook, Instagram } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../consts/routes";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Box
      width={"100%"}
      padding={isMobile ? "20px 16px" : "40px 108px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      component={"div"}
      id="footer"
    >
      <Grid container maxWidth={isMobile ? "600px" : "100%"}>
        <Grid item xs={12} md={6}>
          <Stack direction={"column"} height={"184px"}>
            <Typography sx={themeConfig.typography.footerHeader}>
              Lawendowy Eden
            </Typography>
            <Typography sx={themeConfig.typography.textPrimary}>
              {t("footer.description.breeding")}
            </Typography>
            <Typography sx={themeConfig.typography.textPrimary} mt={"12px"}>
              {t("footer.description.breeds")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={3}>
          <Stack
            direction={"column"}
            height={"186px"}
            width={"184px"}
            justifyContent={"space-between"}
          >
            <Typography sx={themeConfig.typography.footerHeader}>
              {t("footer.labels.breeds")}
            </Typography>
            <Typography
              sx={themeConfig.typography.textPrimary}
              onClick={() => navigate(routes.amstaff)}
            >
              Amstaff
            </Typography>
            <Typography
              sx={themeConfig.typography.textPrimary}
              onClick={() => navigate(routes.americanBully)}
            >
              American Bully
            </Typography>
            <Typography
              sx={themeConfig.typography.textPrimary}
              onClick={() => navigate(routes.bulterier)}
            >
              Bulterier
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={3}>
          <Stack direction={"column"} height={"186px"} width={"184px"}>
            <Typography sx={themeConfig.typography.footerHeader}>
              {t("footer.labels.contact")}
            </Typography>
            <Typography sx={themeConfig.typography.textPrimary}>
              {t("footer.contact.phone")}
            </Typography>
            <Typography sx={themeConfig.typography.textPrimary} mt={"12px"}>
              {t("footer.contact.address")}
            </Typography>
            <Typography sx={themeConfig.typography.textPrimary}>
              {t("footer.contact.city")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        mt={isMobile ? "24px" : "12px"}
        maxWidth={isMobile ? "600px" : "100%"}
      >
        <Grid item xs={6}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
          >
            <IconButton
              sx={{
                padding: 0,
                paddingRight: "8px",
              }}
            >
              <Facebook
                sx={{
                  fill: "#000",
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                padding: 0,
              }}
            >
              <Instagram
                sx={{
                  fill: "#000",
                }}
              />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={themeConfig.typography.footerSmallText}>
            Lawendowy Eden | {new Date().getFullYear()}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export { Footer };
