import { PageWrapper } from "../ui/components/page-wrapper";
import { AboutSection } from "../ui/sections/home/about";
import { BreedsIconsSection } from "../ui/sections/home/breeds-icons";
import { HeroSection } from "../ui/sections/home/hero";
import { InfoSection } from "../ui/sections/home/info";
import { LocalizationSection } from "../ui/sections/home/localization";
import { ShowcaseSection } from "../ui/sections/home/showcase";

const Homepage = () => {
  return (
    <PageWrapper>
      <HeroSection />
      <BreedsIconsSection />
      <AboutSection />
      <InfoSection />
      <ShowcaseSection />
      <LocalizationSection />
    </PageWrapper>
  );
};

export default Homepage;
