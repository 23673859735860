import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import { themeConfig } from "../../../../theme";

const LocalizationSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <Box component={"div"} id="localization">
      <Grid container padding={isMobile ? "60px 16px" : "120px 108px"}>
        <Grid item xs={12} md={6}>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            <Box maxWidth={"482px"} textAlign={isMobile ? "center" : "inherit"}>
              <Typography sx={themeConfig.typography.headerSecondary}>
                {t("localization.title")}
              </Typography>
              <Typography sx={themeConfig.typography.textPrimary} mt={"24px"}>
                {t("localization.description")}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            mt={isMobile ? "48px" : 0}
            display={"flex"}
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            <img
              src="/localization.png"
              alt="localization"
              style={{
                borderRadius: "40px",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export { LocalizationSection };
