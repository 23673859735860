import React from "react";
import ReactDOM from "react-dom/client";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";

import "swiper/css";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

import { I18nextProvider } from "react-i18next";
import i18n from "./translations/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>
);
