import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { themeConfig } from "../../../../theme";

const AmstaffHeroSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <Grid
      container
      padding={isMobile ? "44px 16px" : "88px 108px"}
      minHeight={"100vh"}
    >
      <Grid
        item
        xs={12}
        md={6}
        order={{
          xs: 2,
          md: 1,
        }}
      >
        <Stack
          height={"100%"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={isMobile ? "center" : "flex-start"}
          mt={isMobile ? "24px" : 0}
        >
          <Box maxWidth={"494px"} textAlign={isMobile ? "center" : "inherit"}>
            <Typography sx={themeConfig.typography.headerPrimary}>
              {t("amstaff-page.hero.title")}
            </Typography>
            <Typography
              mt={"32px"}
              mb={"41px"}
              sx={themeConfig.typography.textPrimary}
            >
              {t("amstaff-page.hero.description")}
            </Typography>
            <Typography
              mt={"18px"}
              mb={"41px"}
              sx={themeConfig.typography.textPrimary}
            >
              {t("amstaff-page.hero.secondDescription")}
            </Typography>
            <Typography
              mt={"18px"}
              mb={"41px"}
              sx={themeConfig.typography.textPrimary}
            >
              {t("amstaff-page.hero.thirdDescription")}
            </Typography>

            <Typography
              mt={"18px"}
              mb={"41px"}
              sx={themeConfig.typography.textPrimary}
            >
              {t("amstaff-page.hero.additionalDescription")}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        order={{
          xs: 1,
          md: 2,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={isMobile ? "center" : "flex-end"}
          width={"100%"}
        >
          <Box>
            <img
              src="/amstaff.jpg"
              alt="amstaff-hero-dog"
              style={{
                borderRadius: "40px",
                width: "100%",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export { AmstaffHeroSection };
