import { routes } from "./routes";

export const BREEDS_CONFIG = [
  {
    imageSrc: "/pawVioletIcon.png",
    alt: "paw-violet-icon",
    width: 48,
    height: 48,
    title: "Amstaff",
    route: routes.amstaff,
  },
  {
    imageSrc: "/pawYellowIcon.png",
    alt: "paw-yellow-icon",
    width: 48,
    height: 48,
    title: "American Bully",
    route: routes.americanBully,
  },
  {
    imageSrc: "/pawGreenIcon.png",
    alt: "paw-green-icon",
    width: 48,
    height: 48,
    title: "Bulterier",
    route: routes.bulterier,
  },
];

export const DOG_SHOWCASE_IMAGES = [
  {
    source: "/dog1.png",
    alt: "dog1",
  },
  {
    source: "/dog2.png",
    alt: "dog2",
  },
  {
    source: "/dog3.png",
    alt: "dog3",
  },
  {
    source: "/dog4.png",
    alt: "dog4",
  },
  {
    source: "/dog5.png",
    alt: "dog5",
  },
  {
    source: "/dog6.png",
    alt: "dog6",
  },
];
