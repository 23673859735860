import { PageWrapper } from "../ui/components/page-wrapper";
import { AmericanBullyHeroSection } from "../ui/sections/american-bully/hero";

const AmericanBullyBreedPage = () => {
  return (
    <PageWrapper>
      <AmericanBullyHeroSection />
    </PageWrapper>
  );
};

export default AmericanBullyBreedPage;
